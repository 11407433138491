import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"

import Pricing from "../components/Index/Pricing"
import {Link} from 'gatsby'

const MembershipLevels = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Member Ship Levels" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Member Ship Levels" 
            />

            <Pricing />

            <Footer />
        </Layout>
    )
}

export default MembershipLevels